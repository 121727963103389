import React, { useEffect } from 'react';

import { Helmet } from 'react-helmet'
import {useLocation} from '@reach/router'

const SeoHelmet = (props) => {

    useEffect(() => {
        const script = document.createElement('script');
        const weglotScript = document.createElement('script');
        script.type = "text/javascript";
        script.src = "https://cdn.weglot.com/weglot.min.js"
        weglotScript.innerHTML = `Weglot.initialize({
            api_key: 'wg_706b478f7e11587f4790274e500a516b4'
        });`
        document.head.appendChild(script);
        document.head.appendChild(weglotScript);
    })

    var shareImage = null;
    if (props?.provider === "myCrm") {
        if (props?.images) {
            shareImage = props.images[0].url;
        }
    } else if (props?.shareImage) {
        if (props?.shareImage[0].file) {
            shareImage = `https:${props.shareImage[0].file?.url}`;
        } else {
            shareImage = `https:${props.shareImage[0].gatsbyImageData.images.fallback.src}`;
        }
    }else if(props?.ogimage){
        shareImage = `https:${props?.ogimage.gatsbyImageData.images.fallback.src}`
    }
    else{
        shareImage = "https://25spaces.com/icons/icon-192x192.png?v=3aaed720cfcb2ceb14cd2dabede9e651"
    }
    const {pathname} = useLocation();
    //console.log("pathname",pathname)
    var curentUrl = '';
    var canonicalUrl = '';
    curentUrl = process.env.GATSBY_SITE_URL+pathname;
    if (!curentUrl.endsWith('/'))
        curentUrl = curentUrl + '/';
  
    //remove multiple slashes
    curentUrl = curentUrl.replace(/([^:]\/)\/+/g, "$1");
    //set lowercase url
    curentUrl = curentUrl.toLowerCase();
    if(curentUrl.includes("page")){
        canonicalUrl = curentUrl.split("page")[0] 
    }else{
        canonicalUrl = curentUrl  
    }

    "https://25spaces-dev.q.starberry.com/properties/page-2/".split("page").splice(-1)

    var robotContent = "all";
   
    if(pathname === "/general-page-sample-do-not-delete" || pathname === "/commercial-properties/commercial-property-sample-do-not-delete"){
        robotContent = "noindex";
    }
   
    var ogTitle = ""
    if(props?.shareTitle){
        ogTitle =  props?.shareTitle
    }else{
        ogTitle = props?.metaTitle
    }

    var ogUrl = ""
    if(props.shareUrl){
        ogUrl = props.shareUrl
    }else{
        ogUrl = curentUrl
    }
  

    return (
        <Helmet title={props.metaTitle}>
            <link rel="alternate" hreflang="en" href="https://25spaces.com" />
            <link rel="alternate" hreflang="ar" href="https://ar.25spaces.com" />
            <link rel="canonical" href={canonicalUrl}/>
            <meta name="description" content={props.metaDescription} />
            <meta name="robots" content={robotContent} />
            <meta property="og:site_name" content="25spaces" />
            <meta property="og:type" content="website" />
            {ogUrl ? <meta property="og:url" content={ogUrl} /> : ""}
            {ogTitle ? <meta property="og:title" content={ogTitle} /> : ""}
            {shareImage ? <meta property="og:image" itemprop="image" content={shareImage} /> : ""}  
            <meta property="og:description" content={props.metaDescription} />
            <meta name="google-site-verification" content="zK1Yo2p6GTsC2HvoagUfe8EP_YxjiGXVL_dgf8FbltE" />
        </Helmet>
    )
}

export default SeoHelmet